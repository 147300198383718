import { Amplify } from "aws-amplify"
import outputs from "./amplify_outputs.json"
import { generateClient } from "aws-amplify/data";
import type { Schema } from "./resource"

import { BigNumber, ethers, providers } from 'ethers'
import { FeeAmount } from '@uniswap/v3-sdk'
import { ChainId, Token } from '@uniswap/sdk-core'
import QuoterV2 from '@uniswap/v3-periphery/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
const READABLE_FORM_LEN = 4

Amplify.configure(outputs)

const client = generateClient<Schema>()

const { data: items, errors } = await client.models.TokenPrice.list({
  limit: 5000,
  filter: {
      symbol: { eq: "BULL" },
      chain: { eq: "BNB" }
  }
})

// timeの重複は削除する
export const bull_price_data = Array.from(
  new Map(items.map((x) => [x.time, x])).values()
)


function fromReadableAmount(
  amount: number,
  decimals: number
): BigNumber {
  return ethers.utils.parseUnits(amount.toString(), decimals)
}

function toReadableAmount(rawAmount: number, decimals: number): string {
  return ethers.utils
      .formatUnits(rawAmount, decimals)
      .slice(0, READABLE_FORM_LEN)
}

const QUOTER_CONTRACT_ADDRESS = "0x78D78E420Da98ad378D7799bE8f4AF69033EB077"
const USDT_TOKEN = new Token(
  ChainId.BNB,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Binance Bridged USDT'
)
  
const BULL_TOKEN = new Token(
  ChainId.BNB,
  '0x44e6dEf9bB2dd919CE69a6CB2e3D8a676CF0F413',
  18,
  'BULL',
  'Bull Dog'
)

const CurrentConfig = {
  rpc: {
      mainnet: 'https://bsc-mainnet.infura.io/v3/a2eaa264cda240acae4ec69310af8399',
  },
  tokens: {
      in: USDT_TOKEN,
      amountIn: 1,
      out: BULL_TOKEN,
      poolFee: FeeAmount.MEDIUM,
  },
}

function getProvider(): providers.Provider {
  return new ethers.providers.JsonRpcProvider(CurrentConfig.rpc.mainnet)
}

async function quote(): Promise<string> {
  const quoterContract = new ethers.Contract(
    QUOTER_CONTRACT_ADDRESS,
    QuoterV2.abi,
    getProvider()
  )
  const poolConstants = {
    token0: BULL_TOKEN.address,
    token1: USDT_TOKEN.address,
    fee: FeeAmount.MEDIUM
  }

  const params = {
    tokenIn: poolConstants.token0,
    tokenOut: poolConstants.token1,
    amountIn: fromReadableAmount(
      CurrentConfig.tokens.amountIn,
      CurrentConfig.tokens.in.decimals
    ).toString(),
    fee: FeeAmount.MEDIUM,
    sqrtPriceLimitX96: 0
  }

  const { amountOut, sqrtPriceX96AfterList, initializedTicksCrossedList, gasEstimate } = await quoterContract.callStatic.quoteExactInputSingle(
    params
  )

  return toReadableAmount(amountOut, CurrentConfig.tokens.out.decimals)
}

export function pushBullPrice() {
  quote().then((value) => {
    const currentUtcTimestamp = Math.floor(new Date(Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    )).getTime() / 1000);
    const ret = client.models.TokenPrice.create({
      symbol: "BULL",
      chain: "BNB",
      time: currentUtcTimestamp,
      value: parseFloat(value)
    })
  })
}